.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f8f8;
  padding: 31px 37px;
  border-bottom: solid 1px #d5d5d5;
}

.buttons {
  flex: 1 1 auto;
  display: flex;
  flex-wrap: nowrap;
}

.buttons > button {
  width: 260px;
  margin-right: 19px;
}


.actingAsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #C6A863;
  padding: 15px 19px;
  border-bottom: solid 1px #d5d5d5;
}

.pendingStatusContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #d6b826;
  padding: 15px 19px;
  border-bottom: solid 1px #d5d5d5;
}

.rejectedStatusContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #D62646;
  padding: 15px 19px;
  border-bottom: solid 1px #d5d5d5;
}

.warning {
  color: #ffffff;
  font-size: 20px;
}

.right {
  float: right;
}

.nobutton {
  background: none;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
  outline: inherit;
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 22px;
  line-height: 1.32;
  color: #ffffff;
  text-decoration: none;
}
