.action {
  cursor: pointer;
  padding: 8px 15px;
  color: #721c24;
  background-color: #f8d7da;
  border: solid 1px #f5c6cb;
  border-radius: .25rem;
  margin-bottom: 15px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.15);
}

.action > h5 {
  margin-top: 0;
  margin-bottom: 6px;
}

.action > p {
  margin-bottom: 0;
}
