.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modalTitle {
  color: #5a8dee;
  font-size: 26px;
  font-weight: normal;
  line-height: 1.15;
  margin: 0;
}

.orderMetas {
  display: flex;
}

.orderMeta {
  border: solid 1px #f4f5f7;
  font-size: 14px;
  color: #475f7b;
  padding: 13px 23px 13px 25px;
  margin-right: 13px;
}

.orderMeta:last-of-type {
  margin-right: 0;
}

.orderMeta > span {
  font-size: 18px;
  font-weight: 500;
  margin-right: 4px;
}

.detail {
  display: flex;
  flex-direction: column;
  border: solid 1px #f4f5f7;
  padding: 10px;
  margin-top: 10px;
}

.detail > span {
  margin: 3px 10px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.67;
  color: #9f9f9f;
}

.orderItems {
  margin-top: 10px;
}

.orderItem {
  display: flex;
  border: solid 1px #f4f5f7;
  padding: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.83;
  color: #475f7b;
}

.orderItem > span {
  flex: 1;
  padding-right: 9px;
  margin-right: 9px;
  border-right: solid 1px #f4f5f7;
}

.orderItem > span:last-of-type {
  border-right-width: 0;
}

.markAsShippedButton {
  flex-basis: 60px;
}

.peopleDetails {
  display: flex;
}

.peopleDetails > div {
  flex: 1;
  margin-right: 10px;
}

.peopleDetails > div:last-of-type {
  margin-right: 0;
}

.peopleDetails > .detail > h4 {
  margin: 0 0 0 9px;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.38;
  color: #475f7b;
}

.peopleDetails > .detail > h5 {
  margin: 0 0 0 9px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.38;
  color: #475f7b;
}

.buttons {
  display: flex;
  margin-top: 36px;
}

.buttons > button {
  margin-right: 8px;
}

.buttons > button:last-of-type {
  margin-right: 0;
}
