.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 26px;
}

.label {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.67;
  color: #9f9f9f;
  margin-bottom: 9px;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  position: relative;
  border: solid 1px #dcdfe1;
  cursor: pointer;
}

.input {
  display: none;
}

.instruction {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.67;
  color: #aeaeae;
  margin-left: 10px;
}

.preview {
  object-fit: contain;
}

.icon {
  position: absolute;
  width: 12px;
  height: 12px;
  padding: 6px;
  left: 10px;
  bottom: 10px;
  background-color: #fff;
  border: solid 1px #dcdfe1;
  border-radius: 4px;
}
