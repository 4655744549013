.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 320px;
  height: 300px;
  border-radius: 4px;
  border: solid 1px #c7cfd6;
  background-color: #ffffff;
  cursor: pointer;
  position: relative;
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 20px;
}

.image {
  background-color: #fafbfb;
  object-fit: contain;
  border-bottom: solid 1px #e3e7ea;
}

.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.39;
  color: #475f7b;
}

.subtitle {
  font-size: 18px;
  line-height: 1.56;
  color: #828d99;
}

.untappd {
  color: #828d99;
  font-size: 10px;
}
