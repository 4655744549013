.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

.pages {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: solid 1px #e7edf3;
}

  .pages > .pageButton {
    border: 0;
  }

.pageButton {
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  width: 35px;
  height: 35px;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #e7edf3;
}

.pageButton:hover,
.activePageButton {
  color: #ffffff;
  background-color: #5a8dee;
}

.divider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
}
