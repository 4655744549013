.amount {
  line-height: 1.43;
  color: #263240;
}

.brandOrder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.brandOrderId {
  line-height: 1.36;
  color: #5a8dee;
}

.newTag {
  border-radius: 5px;
  background-color: #ff5a5b;
  padding: 3px 15px;
  color: #fff;
}

.filter {
  margin-left: 12px;
}

.batchOptionsButton {
  padding: 0 10px;
  width: 110px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.pagination > div {
  margin-left: 20px;
  margin-top: 10px;
}
