.summaries {
  display: flex;
  margin-bottom: 39px;
}

  .summaries > div {
    margin-right: 14px;
  }

  .summaries > div:last-of-type {
    margin-right: 0;
  }

