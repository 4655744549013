.container {
  display: flex;
}

/* 
 * The input style are copied from GenericInput.module.css
 * The input style is to override the native DatePicker style 
*/
:global .react-datepicker__input-container input {
  width: calc(100% - 32px);
  height: 40px;
  padding: 4px 15px;
  border-radius: 4px;
  border: solid 1px #c7cfd6;
  font-size: 15px;
  line-height: 1.67;
  color: #9f9f9f;
}

:global .react-datepicker__input-container input:hover {
  border-color: #707070;
}

:global .react-datepicker__input-container input:focus {
  outline: none;
  box-shadow: 0 0 0 1px #2684FF;
  border-color: #2684FF;
}