.container {
  display: flex;
}

.nobutton {
  background: none;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
  outline: inherit;
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 22px;
  line-height: 1.32;
  color: #818d99;
  text-decoration: none;
}

.group {
  flex-direction: row;
}

.spacer {
  padding-left: 10px;
  padding-right: 10px;
}

.profileName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-right: 13px;
}

.profileNameLabel {
  font-size: 29px;
  line-height: 1.17;
  color: #042f3d;
}

.profileLink {
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 22px;
  line-height: 1.32;
  color: #818d99;
  text-decoration: none;
}

/* .imageContainer {
  padding: 10px;
  border-radius: 100%;
  border: solid 1px #ebebeb;
  background-color: #ffffff;
} */

.image {
  padding: 10px;
  height: 55px;
  object-fit: cover;
}
