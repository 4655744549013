.details {
  display: flex;
  flex-direction: column;
  border: solid 1px #c7cfd6;
  padding: 10px;
  margin-top: 10px;
}

.details > h3 {
  margin: 3px 10px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.67;
  color: #9f9f9f;
}

.detailRows {
  display: flex;
  flex-direction: column;
}

.detailRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 6px 6px 18px;
  margin-bottom: 6px;
  border: solid 1px #c7cfd6;
}

.detailRow > button {
  width: 140px;
  margin-left: 6px;
}

.detailBlock {
  flex: 1;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.83;
  color: #475f7b;
}

.detailBlock > span {
  border-right: solid 1px #c7cfd6;
  margin-right: 6px;
  padding-right: 6px;
}

.detailButton {
  display: flex;
  justify-content: flex-end;
}

.detailButton > button {
  width: 260px;
}

.peopleDetails {
  display: flex;
}

.peopleDetails > div {
  flex: 1;
  margin-right: 10px;
}

.peopleDetails > div:last-of-type {
  margin-right: 0;
}

.peopleDetails > .details > h4 {
  margin: 0 0 0 9px;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.38;
  color: #475f7b;
}

.peopleDetails > .details > p {
  margin: 0 0 0 9px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.38;
  color: #475f7b;
}

.buttons {
  margin-top: 15px;
}
