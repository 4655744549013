.container {
  margin-right: 55px;
}

.toggleButton {
  cursor: pointer;
  position: relative;
  border: 0;
  outline: 0;
  background-color: transparent;
}

.badge {
  position: absolute;
  top: -30%;
  right: -20%;
}
