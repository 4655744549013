.label {
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 2.07;
}

.colorActive {
  color: #42c381;
}

.colorDraft {
  color: #efb321;
}

.colorInactive {
  color: #db6566;
}
