.container {
  display: flex;
  align-items: center;
}

.platform {
  display: flex;
  position: relative;
  top: -50%;
  padding-left: 28px;
  cursor: pointer;
  user-select: none;
}

.checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.disabled {
  cursor: not-allowed;
}

.checkbox:checked ~ .checkmark {
  background-color: #fff;
  box-shadow: 0 0 6px 0 rgba(90, 141, 238, 0.6);
  border: solid 1px #e1e1e1;
}

.checkbox:checked ~ .checkmark:after {
  display: block;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  border: solid 1px #c7cfd6;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 3px;
  width: 4px;
  height: 8px;
  border-style: solid;
  border-color: #5a8dee;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.label {
  font-size: 14px;
  line-height: 20px;
}
