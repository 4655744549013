.container {
  display: flex;
  align-items: stretch;
  width: 280px;
  border-radius: 4px;
  border: solid 1px #c7cfd6;
  background-color: #ffffff;
  padding: 10px 20px;
  cursor: pointer;
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.title {
  font-size: 19px;
  line-height: 1.32;
  color: #475f7b;
}

.subtitle {
  font-size: 19px;
  line-height: 1.53;
  color: #828d99;
}

.content {
  margin-left: 18px;
}

.selected {
  border-color: #4064a8;
  border-width: 2px;
}
