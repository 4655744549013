.container {
  margin-bottom: 9px;
}

.buttonContainer {
  display: flex;
  width: 270px;
}

.buttonContainer > button {
  margin-right: 9px;
}

.paragraph {
  font-size: 15px;
  line-height: 1.67;
  color: #9f9f9f;
}
