.modalHeader {
  display: flex;
  align-items: center;
}

.title {
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 1.15;
  color: #475f7b;
  margin: 0 0 0 20px;
}

.untappdIcon {
  width: 100px;
  height: 74px;
  object-fit: contain;
}

.description {
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.39;
  color: #9f9f9f;
}

.form {
  position: relative;
}

.statusTag {
  position: absolute;
  top: 10px;
  right: 10px;
}

.saveButton {
  width: 260px;
  margin-top: 23px;
}
