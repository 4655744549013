.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 26px;
}

.container:last-of-type {
  margin-bottom: 0;
}

.label {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.67;
  color: #9f9f9f;
  margin-bottom: 9px;
}

.inputContainer {
  display: inline-flex;
  align-items: flex-end;
}

.fixtureLabel {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.68;
  color: #9f9f9f;
}

.prefix {
  margin-right: 9px;
}

.suffix {
  margin-left: 9px;
}

.input {
  width: calc(100% - 32px);
  height: 40px;
  padding: 4px 15px;
  border-radius: 4px;
  border: solid 1px #c7cfd6;
  font-size: 15px;
  line-height: 1.67;
  color: #9f9f9f;
}

.input:hover {
  border-color: #707070;
}

.input:focus {
  outline: none;
  box-shadow: 0 0 0 1px #2684FF;
  border-color: #2684FF;
}

/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input[type=number] {
  -moz-appearance: textfield;
}


.endButton {
  height: 50px;
  border: none;
  padding: 10px 0px;
  margin: 0;
  text-decoration: none;
  font-size: 15px;
  line-height: 1.67;
  color: #9f9f9f;
  background-color: #ffffff;
  border-radius: 0px 4px 4px 0px;
  border: solid 1px #c7cfd6;
  width: 60px;
}


.inputPassword {
  border-radius: 4px 0px 0px 4px;
}
