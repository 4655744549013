.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 73px;
}

.selfDistributingStates {
  width: 92%;
  margin-bottom: 24px;
}

.title {
  margin-right: 24px;
}

.inputRow {
  margin-top: 1em;
  display: flex;
}

.inputRow > div {
  flex: 0 0 30%;
  margin-right: 14px;
}

.removeButton {
  width: 100px;
  margin-top: 34px;
}

.buttons {
  margin-top: 1em;
  display: flex;
}

.buttons > button {
  width: 300px;
  margin-right: 18px;
}
