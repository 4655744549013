.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 30px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: solid 1px #f4f5f7;
  border-bottom-width: 0;
  background-color: #ffffff;
}
