.addRetailer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.addRetailer > button {
  flex: 0 0 150px;
  margin-left: 15px;
}

.retailerName {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.amount {
  color: #263240;
}

.newTag {
  font-size: 14px;
  line-height: 17px;
  border-radius: 5px;
  background-color: #ff5a5b;
  padding: 3px 10px;
  color: #fff;
}
