.threeColumnRow {
  display: flex;
  justify-content: space-between;
}

.threeColumnRow > div {
  flex: 1 1 30%;
  margin-right: 14px;
}

.uniqueContainer {
  display: flex;
  flex-direction: column;
  position: relative;
}

.unique {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -100%);
}

.label {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.67;
  color: #9f9f9f;
  margin-bottom: 9px;
}
