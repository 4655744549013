.container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  padding: 7px 16px;
  font-size: 13px;
  line-height: 1.15;
}

.colorOk {
  color: #39da8a;
  background-color: #d2ffe8;
}

.colorInfo {
  color: #5b8eee;
  background-color: #e2ecff;
}

.sizeSmall {
  padding: 5px 12px;
  font-size: 10px;
  line-height: 1.2;
}

.colorNewOrder {
  color: #ffffff;
  background-color: #b22222;
}

.colorShipped {
  color: #000;
  background-color: #fdfd96;
}

.colorPartlyReceived {
  color: #000;
  background-color: #5b8eee;
}

.colorReceived {
  color: #000;
  background-color: #39da8a;
}
