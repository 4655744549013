.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 26px;
}

.label {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.67;
  color: #9f9f9f;
  margin-bottom: 9px;
}

.images {
  display: flex;
  align-items: center;
}

.images > div {
  margin-bottom: 0;
  margin-right: 12px;
}

.images > div:last-of-type {
  margin-right: 0;
}
