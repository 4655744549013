.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}

.container:last-of-type {
  margin-bottom: 0;
}

.container > button {
  margin-right: 5px;
}

.container > button:last-of-type {
  margin-right: 0;
}
