.formSection {
  margin-bottom: 9px;
}

.form {
  display: flex;
  flex-direction: column;
}

.inputContainer {
  display: flex;
}

.inputContainer > div {
  flex: 1;
  margin-right: 9px;
  margin-bottom: 9px;
}

.buttonContainer {
  width: 270px;
}
