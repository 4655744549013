.form {
  margin-left: 63px;
  margin-bottom: 63px;
}

.formSection {
  margin-bottom: 47px;
}

.availableSwitch {
  margin-left: 25px;
}
