.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 26px;
}

.container:last-of-type {
  margin-bottom: 0;
}

.label {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.67;
  color: #9f9f9f;
  margin-bottom: 9px;
}
