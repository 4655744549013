.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 4px 0 24px 0;
}

  .title {
    font-size: 26px;
    font-weight: normal;
    line-height: 1.15;
    color: #475f7b;
    margin: 0;
  }

  .closeButton {
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    z-index: 103;
    position: absolute;
    top: 20px;
    right: 28px;
    padding: 0;
    height: 30px;
  }
