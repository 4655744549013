.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 53px 48px 0;
}

.title {
  display: flex;
  align-items: center;
}

.titleLabel {
  font-weight: normal;
  font-size: 37px;
  line-height: 1.19;
  color: #475f7b;
}

.icon {
  width: 43px;
  height: 43px;
  margin-right: 20px;
}

.children {
  flex: 1;
}
