.container {}

.section {
  margin-bottom: 38px;
}

.sectionTitle {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 2px;
  color: #bac0c7;
  margin-bottom: 18px;
}

.brands {
  display: flex;
  flex-wrap: wrap;
}

.brands > div {
  margin-right: 18px;
  margin-bottom: 18px;
}

.syncButton {
  cursor: pointer;
  color: #efb321;
  margin-left: 20px;
}
