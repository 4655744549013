.container {
  display: flex;
  flex-direction: column;
  border: solid 1px #c7cfd6;
  border-bottom-width: 0;
  background-color: #ffffff;
}

.collapseContainer {
  padding: 20px 30px;
}

.collapsed {
  display: none;
}

.toggleButton {
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 18px;
  line-height: 1.61;
  text-align: center;
  padding: 0 22px;
  width: 100%;
  height: 48px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  color: #9f9f9f;
}

.toggleButton:hover {
  background-color: #fafafa;
}

.toggleButton:active {
  background-color: #ececec;
}
