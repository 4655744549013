.container {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 362px;
  background-color: #042f3d;
  z-index: 1;
}

.logoContainer {
  background-color: #02242f;
  padding: 26px 0 20px 38px;
}

.navContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 10px;
}

.navItem {
  display: flex;
  align-items: center;
  padding: 15px 23px 10px 38px;
  font-size: 22px;
  line-height: 1.23;
  color: #ffffff;
  text-decoration: none;
  margin: 9px 0;
}

.navItem:hover {
  background-color: #02242f;
}

.navItemLabel {
  flex: 1 1 auto;
}

.navItemActive {
  background-color: #02242f;
}

.navIcon {
  width: 32px;
  height: 32px;
  object-fit: contain;
  margin-right: 13px;
}

.partitionTitle {
  font-size: 14px;
  margin-top: 32px;
  padding: 15px 23px 10px 38px;
  color: #e9e9e9;
}
