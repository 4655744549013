.root {
  position: fixed;
  bottom: 15px;
  left: 15px;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 999999999999;
}

  .root p {
    margin: 0;
  }
