.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.image {
  width: 35px;
  height: 35px;
}

.content {
  max-width: 540px;
  margin: 35px;
  font-size: 1.26em;
}

.button {
  width: 260px;
}

.typeSuccess {
  color: darkgreen;
}

.typeError {
  color: darkred;
}
