.container {
  display: flex;
  align-items: center;
}

  .switchContainer {
    display: flex;
    position: relative;
    cursor: pointer;
    width: 39px;
    height: 14px;
    border-radius: 12px;
    border: solid 1px #e0e0e0;
    padding: 3px;
    background-color: #ffffff;
  }

    .isSelected {
      border-color: #5a8dee;
      justify-content: flex-end;
      background-color: #5a8dee;
    }

  .knob {
    width: 14px;
    height: 14px;
    border-radius: 100px;
    background-color: #ffffff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  }

  .label {
    flex: 1;
    color: #475f7b;
    margin-left: 12px;
    line-height: 18px;
  }

  .disabled {
    cursor: not-allowed;
  }

    .disabled .switchContainer {
      cursor: not-allowed;
    }
