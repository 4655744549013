.sectionTitle {
  margin: 3px 10px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.67;
  color: #9f9f9f;
}

.notifications {
  margin-top: 10px;
}

.notification {
  display: flex;
  margin-bottom: 9px;
  border: solid 1px #f4f5f7;
  padding: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.83;
  color: #475f7b;
}

.notificationText {
  flex: 1;
  margin-right: 9px;
}

.notificationText:last-of-type {
  border-right-width: 0;
}

.notificationText.read {
  font-weight: 400;
}
