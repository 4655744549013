.formSection {
  margin-bottom: 57px;
}

.fieldContainer {
  margin: 25px 0;
  padding-bottom: 25px;
  border-bottom: solid 1px #c7cfd6;
}

.fieldContainer:last-of-type {
  padding-bottom: 0;
  border-bottom-color: transparent;
}

.checkbox {
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #9f9f9f;
}

.title {
  margin-right: 24px;
}
