.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  justify-content: space-between;
  align-items: stretch;
  background-image: url('../../../../assets/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.header {
  text-align: center;
  background-color: #02242f;
  padding: 27px 0 21px;
}

.content {
  flex: 1 1 auto;
  margin: 100px auto;
}

.contentBox {
  width: 635px;
  min-height: 600px;
  border-radius: 13px;
  box-shadow: 10px 3px 15px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}
