.cost {
  margin-bottom: 18px;
}

.costBreakdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  line-height: 1.67;
  color: #9f9f9f;
}

.priceTag {
  font-weight: 500;
}

.totalCost {
  margin-top: 9px;
  border-top: solid 1px #9f9f9f;
  font-size: 18px;
}

.button {
  margin-top: 23px;
}

.paragraph {
  font-size: 15px;
  line-height: 1.67;
  color: #9f9f9f;
}

.strong {
  font-weight: bolder;
}
