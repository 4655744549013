.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 73px;
}

.inputRow {
  display: flex;
}

.inputRow > div {
  flex: 0 0 30%;
  margin-right: 14px;
}

.fullWidthInputRow {
  margin-bottom: 23px;
}

.label {
  display: block;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.67;
  color: #9f9f9f;
  margin-bottom: 9px;
}

.syncButton {
  cursor: pointer;
  color: #efb321;
}

.images {
  display: flex;
}

.images > div {
  margin-right: 12px;
}
