.filters {
  display: flex;
  flex-direction: column;
}

.filterRow {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.filterRow > div {
  flex: 1;
  margin-top: 18px;
  margin-right: 18px;
  margin-bottom: 0;
}

.filterRow > div:last-of-type {
  margin-right: 0;
}

.filterButtons {
  display: flex;
  justify-content: flex-end;
  overflow: auto;
  margin-top: 32px;
}

.filterButtons > button {
  width: 180px;
  margin-left: 18px;
}
