.button {
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 18px;
  line-height: 1.61;
  text-align: center;
  padding: 0 22px;
  width: 100%;
  height: 48px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.button:disabled {
  cursor: not-allowed;
}

.buttonLabel {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

/* Size Styles */
.sizeSmall {
  font-size: 14px;
  padding: 0 10px;
  height: 32px;
}

.sizeLarge {
  padding: 0 30px;
  height: 60px;
}

/* Color Styles */
.colorPrimary {
  background-color: #5a8dee;
  color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(90, 141, 238, 0.4);
}

  .colorPrimary:hover {
    background-color: #4064a8;
  }

  .colorPrimary:active {
    background-color: #2c4574;
  }

.colorSecondary {
  background-color: #ffffff;
  color: #475f7b;
  border: solid 1px #e7edf3;
}

  .colorSecondary:hover {
    background-color: #fafafa;
  }

  .colorSecondary:active {
    background-color: #ececec;
  }

.colorForm {
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 15px;
  background-color: #ffffff;
  color: #9f9f9f;
  border: solid 1px #c7cfd6;
}

  .colorForm:hover {
    background-color: #fafafa;
  }

  .colorForm:active {
    background-color: #ececec;
  }

.colorDarken {
  border: solid 1px #707070;
  background-color: #ffffff;
  color: #707070;
}

  .colorDarken:hover {
    background-color: #fafafa;
  }

  .colorDarken:active {
    background-color: #ececec;
  }
