.header {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  height: 350px;
  background-color: #fafbfb;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-bottom: solid 1px #c7cfd6;
}

  .categories {
    display: flex;
    padding: 18px 20px;
  }

    .categories > div {
      margin-right: 8px;
    }

.content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

  .title {
    font-size: 36px;
    line-height: 1.39;
    color: #475f7b;
  }

  .subtitle {
    font-size: 23px;
    line-height: 1.57;
    color: #828d99;
    margin-bottom: 32px;
  }

  .status {
    position: absolute;
    top: 20px;
    right: 20px;
  }

.buttons {
  display: flex;
  padding: 20px;
  justify-content: space-between;
}

  .buttons > button {
    margin-right: 20px;
  }

  .buttons > button:last-of-type {
    margin-right: 0;
  }


  .untappd {
    margin-left: 20px;
    color: #828d99;
    font-size: 10px;
  }
  