.packagingRow {
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px #c7cfd6;
  padding-bottom: 8px;
  margin-bottom: 32px;
}

.packagingRow:last-of-type {
  border-bottom-width: 0;
}

.packagingColumn {
  display: flex;
  align-items: flex-end;
  margin-bottom: 13px;
}

.packagingColumn > div {
  flex: 1 1;
  margin-right: 14px;
  margin-bottom: 0;
}

.ptrBlock {
  display: flex;
  align-items: center;
}

.ptrInputs {
  display: flex;
  align-items: center;
  min-width: 240px;
}

.ptrInputs > div {
  margin-right: 30px;
  margin-bottom: 0;
}

.ptrInput {
  width: 120px;
}

.label {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.67;
  color: #9f9f9f;
  padding-bottom: 9px;
}

.availabilityContainer.availabilityContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.availability {
  flex: 0 0 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.availabilityCheckbox {
  transform: translateY(-10px);
}
