.buttons {
  margin: 18px 0 38px;
}

.buttons > button {
  margin-bottom: 9px;
}

.buttons > button:last-of-type {
  margin-bottom: 0;
}
