.container {
  padding: 45px 70px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerIcon {
  width: 43px;
}

.title {
  margin: 22px auto 11px;
  font-size: 37px;
  line-height: 1.19;
  text-align: center;
  color: #475f7b;
  font-weight: normal;
}

.subtitle {
  font-size: 17px;
  line-height: 1.18;
  text-align: center;
  color: #C6A863;
  margin-bottom: 57px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}

.formButton {
  margin-top: 59px;
  width: 100%;
}

.backButton {
  margin-top: 22px;
  border-color: #707070;
}
