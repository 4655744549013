.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 28px;
}

.title {
  font-size: 26px;
  font-weight: normal;
  line-height: 1.15;
  color: #475f7b;
  margin: 0;
}

.content {
  padding: 20px;
  font-size: 23px;
  line-height: 1.57;
  color: #828d99;
  margin-bottom: 32px;
}

.buttons {
  display: flex;
  align-items: center;
}

.buttons > button {
  margin-right: 16px;
}

.buttons > button:last-of-type {
  margin-right: 0;
}
