.individualForm {
  position: relative;
  margin-bottom: 18px;
}

.bulkForm {
  position: relative;
}

.fileInput {
  display: none;
}

.submitButton {
  position: absolute;
  bottom: 9px;
  right: 9px;
  width: 120px;
}

.uploadButton {
  position: absolute;
  top: -4px;
  right: 0;
  width: 160px;
}

.retailer {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.67;
  color: #9f9f9f;
  margin-bottom: 9px;
}
