.ratings {
  display: flex;
  align-items: center;
}

  .rating {
    flex: 0 0 30%;
    display: flex;
    align-items: center;
    margin-right: 14px;
  }

  .ratingIcon {
    height: 70px;
    margin-right: 30px;
  }

  .ratingInput {
    width: 120px;
  }


.awards {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 43px;
}

  .award {
    display: flex;
    align-items: flex-end;
    margin-bottom: 26px;
  }

  .award > div {
    flex: 1;
    margin-right: 14px;
    margin-bottom: 0;
  }
