.table {
  width: 100%;
  border: solid 1px #f4f5f7;
  table-layout: auto;
  border-collapse: collapse;
}

.tr > th,
.tr > td{
  border-style: solid;
  border-color: #f4f5f7;
  border-width: 1px 0;
}

.tr:hover > td {
  background-color: #e8f1fa;
}

.th {
  padding: 17px 24px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.21;
  color: #475f7b;
}

.td {
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 14px;
  padding: 17px 24px;
  line-height: 1.21;
  color: #828d99;
}

.selectable {
  padding-right: 0;
}

.selectable + td,
.selectable + th {
  padding-left: 0;
}
