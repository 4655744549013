.container {
  flex: 1;
  position: relative;
}

.icon {
  position: absolute;
  top: 17px;
  left: 15px;
}

.input {
  padding-left: 40px;
}