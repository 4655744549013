.container {
  display: flex;
}

.details {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: solid 1px #c7cfd6;
  padding: 10px;
  margin-top: 10px;
  margin-right: 10px;
}

.noStyle {
  border: none;
  padding: 0px;
  margin-top: 0px;
  margin-right: px;
}

.details:last-of-type {
  margin-right: 0;
}

.details > h3 {
  margin: 3px 10px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.67;
  color: #9f9f9f;
}

.details > h4 {
  margin: 0 0 0 9px;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.38;
  color: #475f7b;
}

.details > p {
  margin: 0 0 0 9px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.38;
  color: #475f7b;
}
