.container {
  display: flex;
  align-items: stretch;
  padding-left: 362px;
}

.main {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: stretch;
}

.content {
  flex: 1 1 auto;
  padding: 48px 48px 0;
}
