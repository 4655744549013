.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 285px;
  border-radius: 4px;
  box-shadow: 10px 8px 40px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #ccd3d9;
  background-color: #ffffff;
  padding: 20px 0;
}

.large {
  width: 385px;
}

.value {
  font-size: 37px;
  line-height: 1.19;
  color: #475f7b;
}

.title {
  margin-top: 3px;
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 1.43;
  color: #727e8c;
  text-align: center;
}
