.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 26px;
}

.container:last-of-type {
  margin-bottom: 0;
}

.label {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.67;
  color: #9f9f9f;
  margin-bottom: 9px;
}

.inputContainer {
  display: inline-flex;
  align-items: flex-end;
}

.input {
  width: calc(100% - 32px);
  min-height: 15em;
  padding: 10px 15px;
  border-radius: 4px;
  border: solid 1px #c7cfd6;
  font-size: 15px;
  line-height: 1.67;
  color: #9f9f9f;
  resize: vertical;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.input:hover {
  border-color: #707070;
}

.input:focus {
  outline: none;
  box-shadow: 0 0 0 1px #2684FF;
  border-color: #2684FF;
}

::-webkit-input-placeholder {
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
:-moz-placeholder {
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
::-moz-placeholder {
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
:-ms-input-placeholder {
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
