.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 26px;
}

.container:last-of-type {
  margin-bottom: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.label {
  flex: 1;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.67;
  color: #9f9f9f;
  margin-bottom: 9px;
}

.inputs {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.inputContainer {
  display: flex;
  align-items: stretch;
  border: solid 1px #c7cfd6;
  border-bottom-width: 0;
}

.input {
  flex: 1;
  height: 38px;
  padding: 5px 15px;
  font-size: 15px;
  border-radius: 4px;
  border: none;
  line-height: 1.67;
  color: #9f9f9f;
}

.input:hover,
.input:focus {
  border-color: #707070;
}

.addButton {
  height: 30px;
  flex: 0 0 150px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-width: 0;
}

.inputs > div:first-child {
  border-top-left-radius: 4px;
}

.inputs > div:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-width: 1px;
}
